import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { get } from '../../services/api';
import PollsList from './PollsList';
import PollEditor from './PollEditor';
import CopyPollsForm from './CopyPollsForm';
import SocketClient from '../../utils/socket-client';
import Modal from '../../common/Modal';

export default function Polls({}) {
  const _selectedPollRef = useRef(null);
  const _pollEditorRef = useRef();

  const activeEvent = useSelector((state) => state.events.active);
  const activeStudio = useSelector((state) => state.studio.active);
  const liveEvent = useSelector((state) => (state.studio.active ? state.studio.active.liveEvent : null));
  const routesEnabled = useSelector((state) => state.ui.routesEnabled);
  const eventSelected = useSelector((state) => state.ui.eventSelected);
  const appMode = useSelector((state) => state.ui.appMode);

  const [selectedPoll, setSelectedPoll] = useState(null);
  const [polls, setPolls] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [pollingStatus, setPollingStatus] = useState(null);

  useEffect(() => {
    const _onAdminAppSync = (data) => {
      if (data.type === 'polling-status') {
        setPollingStatus(data.payload);
      } else if (data.type === 'poll-update') {
        setPolls((list) => {
          const index = list.findIndex((poll) => poll._id === data.payload._id);
          if (index === -1) {
            return [...list, data.payload];
          } else {
            list[index] = data.payload;
            if (_selectedPollRef.current && _selectedPollRef.current === data.payload._id) {
              setSelectedPoll(data.payload);
            }
            return [...list];
          }
        });
      } else if (data.type === 'poll-delete') {
        setPolls((list) => list.filter((poll) => poll._id !== data.payload));
        if (_selectedPollRef.current && _selectedPollRef.current === data.payload) {
          setSelectedPoll(null);
        }
      }
    };

    if (activeEvent && activeStudio) {
      SocketClient.joinRoom(`${activeStudio._id}:poll-results-window`);
      SocketClient.on('admin-app-sync', _onAdminAppSync);
      (async () => {
        try {
          const { data } = await get(`/poll/list?event=${activeEvent._id}`);
          setPolls(data);
          if (activeEvent && data.length > 0 && activeEvent.activePoll) {
            const activePoll = data.find((p) => p._id === activeEvent.activePoll);
            setSelectedPoll(activePoll);
          }
        } catch (err) {
          console.error(err);
        }
      })();
    }
    return () => {
      if (activeStudio) {
        SocketClient.leaveRoom(`${activeStudio._id}:poll-results-window`);
      }
      SocketClient.removeAllListeners();
      SocketClient.off('admin-app-sync', _onAdminAppSync);
    };
  }, []);

  useEffect(() => {
    _selectedPollRef.current = selectedPoll ? selectedPoll._id : null;
  }, [selectedPoll]);

  const _onCopyPolls = () => {
    setModalOpen(true);
  };

  const _closeModal = () => {
    setModalOpen(false);
  };

  const _onSubmitCopyPolls = async (event) => {
    setModalOpen(false);
    const { data } = await get(`/poll/list?event=${activeEvent._id}`);
    setPolls(data);
  };

  const _onAddPoll = (poll) => {
    const _polls = [...polls];
    _polls.push(poll);
    setPolls(_polls);
    setSelectedPoll(poll);
  };

  const _onDeletePoll = (poll) => {
    const _polls = [...polls];
    const index = _polls.findIndex((p) => p._id === poll._id);
    if (index !== -1) {
      _polls.splice(index, 1);
      setPolls(_polls);
    }
    if (selectedPoll && selectedPoll._id === poll._id) {
      setSelectedPoll(null);
    }
  };

  const _onUpdatePoll = (updatedPollData) => {
    const _polls = [...polls];
    const index = _polls.findIndex((p) => p._id === selectedPoll._id);
    if (index !== -1) {
      _polls[index] = { ..._polls[index], ...updatedPollData };
      setPolls(_polls);
    }
  };

  if (!routesEnabled) return <Redirect to='/app/studios' />;
  if (!eventSelected) return <Redirect to='/app/events' />;

  return (
    <div className='polls'>
      <PollsList
        polls={polls}
        event={activeEvent}
        onSelectPoll={(poll) => {
          setSelectedPoll(poll);
        }}
        onAddPoll={_onAddPoll}
        onDeletePoll={_onDeletePoll}
        selected={selectedPoll}
        onCopyPolls={_onCopyPolls}
      />
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
        <div className='poll-editor custom-scrollbar'>
          {selectedPoll && (
            <PollEditor
              ref={_pollEditorRef}
              event={activeEvent}
              pollId={selectedPoll._id}
              poll={selectedPoll}
              liveEvent={liveEvent}
              pollingStatus={pollingStatus}
              onUpdatePoll={_onUpdatePoll}
            />
          )}
        </div>
      </div>
      <Modal show={modalOpen} onClose={() => _closeModal(true)}>
        {activeEvent && <CopyPollsForm activeEventId={activeEvent._id} currentPolls={polls} onSubmit={_onSubmitCopyPolls} />}
      </Modal>
    </div>
  );
}
