import React, { useState, useEffect } from 'react';
import CustomRadio from './CustomRadio';

const styles = {
  label: {
    textAlign: 'left',
    color: '#a9aaab',
    marginBottom: 5,
  },
  question: {
    fontSize: 18,
    padding: '15px 5px',
    textAlign: 'left',
  },
  list: {
    margin: 0,
  },
};

export default function PollPreview({ data, prefixAnswersWith, correctAnswerIndex }) {
  const [question, setQuestion] = useState(null);
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    if (data) {
      const parts = data.split('\n').filter((d) => !!d);
      const _responses = [];

      parts.forEach((p, i) => {
        if (i === 0) {
          setQuestion(p);
        } else {
          _responses.push(p);
        }
      });

      setResponses(_responses);
    } else {
      setQuestion(null);
      setResponses([]);
    }
  }, [data]);

  const _renderResponses = (prefixAnswersWith) => {
    return responses.map((resp, i) => {
      const id = `poll-response-${i}`;
      let label;
      if (prefixAnswersWith === 0) {
        label = resp;
      } else if (prefixAnswersWith === 1) {
        label = `${String.fromCharCode(97 + i).toUpperCase()}. ${resp}`;
      } else if (prefixAnswersWith === 2) {
        label = `${i + 1}. ${resp}`;
      }
      return (
        <li key={id} style={{ textAlign: 'left', listStyle: 'none', wordWrap: 'anywhere' }}>
          <CustomRadio label={label} name='answer' value={resp} checked={correctAnswerIndex === i} onChange={() => {}} style={{ pointerEvents: 'none' }} />
        </li>
      );
    });
  };

  return (
    <>
      <div style={styles.label}>Preview</div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', background: '#24313F' }}>
        {question && <div style={styles.question}>{question}</div>}
        <div className='custom-scrollbar' style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden', margin: '5px' }}>
          <ul style={{ ...styles.list, paddingLeft: 5 }}>{_renderResponses(prefixAnswersWith)}</ul>
        </div>
      </div>
    </>
  );
}
