import { createActions, handleActions } from 'redux-actions';

const initialState = {
  awsInstances: null,
  awsImages: null,
  instances: {},
  instancesByInstanceId: {},
  liveData: {},
  list: [],
  fetching: false,
  error: null,
  regions: [],
  availabilityZones: [],
  readyToStartEvent: false,
  allServersRunning: false,
  isLive: false,
  socketServersReady: false,
  compositorServersReady: false,
  decoderServerReady: false,
  rtcServersReady: false,
  runningFromImage: null,
  timestamp: null,
};

export const actions = {
  GET_AWS_REGIONS_REQUEST: 'GET_AWS_REGIONS_REQUEST',
  GET_AWS_REGIONS_SUCCESS: 'GET_AWS_REGIONS_SUCCESS',
  GET_AWS_REGIONS_FAILURE: 'GET_AWS_REGIONS_FAILURE',
  GET_AWS_INSTANCES_REQUEST: 'GET_AWS_INSTANCES_REQUEST',
  GET_AWS_INSTANCES_SUCCESS: 'GET_AWS_INSTANCES_SUCCESS',
  GET_AWS_INSTANCES_FAILURE: 'GET_AWS_INSTANCES_FAILURE',
  GET_AWS_IMAGES_REQUEST: 'GET_AWS_IMAGES_REQUEST',
  GET_AWS_IMAGES_SUCCESS: 'GET_AWS_IMAGES_SUCCESS',
  GET_AWS_IMAGES_FAILURE: 'GET_AWS_IMAGES_FAILURE',
  GET_INSTANCES_REQUEST: 'GET_INSTANCES_REQUEST',
  GET_INSTANCES_SUCCESS: 'GET_INSTANCES_SUCCESS',
  GET_INSTANCES_FAILURE: 'GET_INSTANCES_FAILURE',
  CREATE_INSTANCE_REQUEST: 'CREATE_INSTANCE_REQUEST',
  CREATE_INSTANCE_SUCCESS: 'CREATE_INSTANCE_SUCCESS',
  CREATE_INSTANCE_FAILURE: 'CREATE_INSTANCE_FAILURE',
  UPDATE_INSTANCE_REQUEST: 'UPDATE_INSTANCE_REQUEST',
  UPDATE_INSTANCE_SUCCESS: 'UPDATE_INSTANCE_SUCCESS',
  UPDATE_INSTANCE_FAILURE: 'UPDATE_INSTANCE_FAILURE',
  DELETE_INSTANCE_REQUEST: 'DELETE_INSTANCE_REQUEST',
  DELETE_INSTANCE_SUCCESS: 'DELETE_INSTANCE_SUCCESS',
  DELETE_INSTANCE_FAILURE: 'DELETE_INSTANCE_FAILURE',
  START_INSTANCE_REQUEST: 'START_INSTANCE_REQUEST',
  START_INSTANCE_SUCCESS: 'START_INSTANCE_SUCCESS',
  START_INSTANCE_FAILURE: 'START_INSTANCE_FAILURE',
  STOP_INSTANCE_REQUEST: 'STOP_INSTANCE_REQUEST',
  STOP_INSTANCE_SUCCESS: 'STOP_INSTANCE_SUCCESS',
  STOP_INSTANCE_FAILURE: 'STOP_INSTANCE_FAILURE',
  RESTART_INSTANCE_REQUEST: 'RESTART_INSTANCE_REQUEST',
  RESTART_INSTANCE_SUCCESS: 'RESTART_INSTANCE_SUCCESS',
  RESTART_INSTANCE_FAILURE: 'RESTART_INSTANCE_FAILURE',
  GO_LIVE_REQUEST: 'GO_LIVE_REQUEST',
  GO_LIVE_SUCCESS: 'GO_LIVE_SUCCESS',
  GO_LIVE_FAILURE: 'GO_LIVE_FAILURE',
  END_LIVE_REQUEST: 'END_LIVE_REQUEST',
  END_LIVE_SUCCESS: 'END_LIVE_SUCCESS',
  END_LIVE_FAILURE: 'END_LIVE_FAILURE',
  SET_INSTANCE: 'SET_INSTANCE',
  CHECK_INSTANCES_STATUS: 'CHECK_INSTANCES_STATUS',
  SET_LIVE_INSTANCE_DATA: 'SET_LIVE_INSTANCE_DATA',
  LAUNCH_FROM_IMAGE_REQUEST: 'LAUNCH_FROM_IMAGE_REQUEST',
  LAUNCH_FROM_IMAGE_SUCCESS: 'LAUNCH_FROM_IMAGE_SUCCESS',
  LAUNCH_FROM_IMAGE_FAILURE: 'LAUNCH_FROM_IMAGE_FAILURE',
  CLEAR_LIVE_INSTANCE_DATA: 'CLEAR_LIVE_INSTANCE_DATA',
  GET_AWS_AVAILABILITY_ZONES_REQUEST: 'GET_AWS_AVAILABILITY_ZONES_REQUEST',
  GET_AWS_AVAILABILITY_ZONES_SUCCESS: 'GET_AWS_AVAILABILITY_ZONES_SUCCESS',
  GET_AWS_AVAILABILITY_ZONES_FAILURE: 'GET_AWS_AVAILABILITY_ZONES_FAILURE',
};

export const {
  getAwsRegionsRequest,
  getAwsRegionsSuccess,
  getAwsRegionsFailure,
  getAwsInstancesRequest,
  getAwsInstancesSuccess,
  getAwsInstancesFailure,
  getAwsImagesRequest,
  getAwsImagesSuccess,
  getAwsImagesFailure,
  getInstancesRequest,
  getInstancesSuccess,
  getInstancesFailure,
  createInstanceRequest,
  createInstanceSuccess,
  createInstanceFailure,
  updateInstanceRequest,
  updateInstanceSuccess,
  updateInstanceFailure,
  deleteInstanceRequest,
  deleteInstanceSuccess,
  deleteInstanceFailure,
  startInstanceRequest,
  startInstanceSuccess,
  startInstanceFailure,
  stopInstanceRequest,
  stopInstanceSuccess,
  stopInstanceFailure,
  restartInstanceRequest,
  restartInstanceSuccess,
  restartInstanceFailure,
  goLiveRequest,
  goLiveSuccess,
  goLiveFailure,
  endLiveRequest,
  endLiveSuccess,
  endLiveFailure,
  setInstance,
  checkInstancesStatus,
  setLiveInstanceData,
  clearLiveInstanceData,
  launchFromImageRequest,
  launchFromImageSuccess,
  launchFromImageFailure,
  getAwsAvailabilityZonesRequest,
  getAwsAvailabilityZonesSuccess,
  getAwsAvailabilityZonesFailure,
} = createActions({
  [actions.GET_AWS_REGIONS_REQUEST]: () => ({}),
  [actions.GET_AWS_REGIONS_SUCCESS]: (data) => ({ data }),
  [actions.GET_AWS_REGIONS_FAILURE]: (error) => ({ error }),
  [actions.GET_AWS_INSTANCES_REQUEST]: (regionId) => ({ regionId }),
  [actions.GET_AWS_INSTANCES_SUCCESS]: (data) => ({ data }),
  [actions.GET_AWS_INSTANCES_FAILURE]: (error) => ({ error }),
  [actions.GET_AWS_IMAGES_REQUEST]: (regionId) => ({ regionId }),
  [actions.GET_AWS_IMAGES_SUCCESS]: (data) => ({ data }),
  [actions.GET_AWS_IMAGES_FAILURE]: (error) => ({ error }),
  [actions.GET_INSTANCES_REQUEST]: (studio, monitoring) => ({ studio, monitoring }),
  [actions.GET_INSTANCES_SUCCESS]: (data) => ({ data }),
  [actions.GET_INSTANCES_FAILURE]: (error) => ({ error }),
  [actions.CREATE_INSTANCE_REQUEST]: (data) => ({ data }),
  [actions.CREATE_INSTANCE_SUCCESS]: (data) => ({ data }),
  [actions.CREATE_INSTANCE_FAILURE]: (error) => ({ error }),
  [actions.UPDATE_INSTANCE_REQUEST]: (id, data) => ({ id, data }),
  [actions.UPDATE_INSTANCE_SUCCESS]: (data) => ({ data }),
  [actions.UPDATE_INSTANCE_FAILURE]: (error) => ({ error }),
  [actions.DELETE_INSTANCE_REQUEST]: (id) => ({ id }),
  [actions.DELETE_INSTANCE_SUCCESS]: (data) => ({ data }),
  [actions.DELETE_INSTANCE_FAILURE]: (error) => ({ error }),
  [actions.START_INSTANCE_REQUEST]: (id) => ({ id }),
  [actions.START_INSTANCE_SUCCESS]: (id) => ({ id }),
  [actions.START_INSTANCE_FAILURE]: (error) => ({ error }),
  [actions.STOP_INSTANCE_REQUEST]: (id) => ({ id }),
  [actions.STOP_INSTANCE_SUCCESS]: (data) => ({ data }),
  [actions.STOP_INSTANCE_FAILURE]: (error) => ({ error }),
  [actions.RESTART_INSTANCE_REQUEST]: (id) => ({ id }),
  [actions.RESTART_INSTANCE_SUCCESS]: (data) => ({ data }),
  [actions.RESTART_INSTANCE_FAILURE]: (error) => ({ error }),
  [actions.GO_LIVE_REQUEST]: (studioId, eventId) => ({ studioId, eventId }),
  [actions.GO_LIVE_SUCCESS]: (data) => ({ data }),
  [actions.GO_LIVE_FAILURE]: (error) => ({ error }),
  [actions.END_LIVE_REQUEST]: (studioId) => ({ studioId }),
  [actions.END_LIVE_SUCCESS]: (data) => ({ data }),
  [actions.END_LIVE_FAILURE]: (error) => ({ error }),
  [actions.SET_INSTANCE]: (data) => ({ data }),
  [actions.CHECK_INSTANCES_STATUS]: (data) => ({ data }),
  [actions.SET_LIVE_INSTANCE_DATA]: (data) => ({ data }),
  [actions.CLEAR_LIVE_INSTANCE_DATA]: (instanceId) => ({ instanceId }),
  [actions.LAUNCH_FROM_IMAGE_REQUEST]: (id) => ({ id }),
  [actions.LAUNCH_FROM_IMAGE_SUCCESS]: (data) => ({ data }),
  [actions.LAUNCH_FROM_IMAGE_FAILURE]: (error) => ({ error }),
  [actions.GET_AWS_AVAILABILITY_ZONES_REQUEST]: (region) => ({ region }),
  [actions.GET_AWS_AVAILABILITY_ZONES_SUCCESS]: (data) => ({ data }),
  [actions.GET_AWS_AVAILABILITY_ZONES_FAILURE]: (error) => ({ error }),
});

const reducer = handleActions(
  {
    [actions.GET_AWS_REGIONS_REQUEST]: (state) => ({
      ...state,
      regions: [],
      fetching: true,
      error: null,
    }),
    [actions.GET_AWS_REGIONS_SUCCESS]: (state, action) => ({
      ...state,
      regions: action.payload.data,
      fetching: false,
      error: null,
    }),
    [actions.GET_AWS_REGIONS_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.GET_AWS_INSTANCES_REQUEST]: (state) => ({
      ...state,
      awsInstances: null,
      fetching: true,
      error: null,
    }),
    [actions.GET_AWS_INSTANCES_SUCCESS]: (state, action) => {
      const awsInstances = action.payload.data
        .map((instance) => {
          return {
            ...instance,
            formSelectLabel: `${instance.name} - ${instance.instanceId}`,
          };
        })
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });

      return {
        ...state,
        awsInstances,
        fetching: false,
        error: null,
      };
    },
    [actions.GET_AWS_INSTANCES_FAILURE]: (state, action) => ({
      ...state,
      awsInstances: null,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.GET_AWS_IMAGES_REQUEST]: (state) => ({
      ...state,
      awsImages: null,
      fetching: true,
      error: null,
    }),
    [actions.GET_AWS_IMAGES_SUCCESS]: (state, action) => ({
      ...state,
      awsImages: action.payload.data,
      fetching: false,
      error: null,
    }),
    [actions.GET_AWS_IMAGES_FAILURE]: (state, action) => ({
      ...state,
      awsImages: null,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.GET_INSTANCES_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.GET_INSTANCES_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      const instances = {};
      const instancesByInstanceId = {};
      const list = data.map((s) => s._id);
      list.forEach((id) => {
        const instance = data.find((s) => s._id === id);
        instances[id] = { ...instance };
        instancesByInstanceId[instance.instanceId] = { ...instance };
      });
      return {
        ...state,
        fetching: false,
        error: null,
        list,
        instances,
        instancesByInstanceId,
      };
    },
    [actions.GET_INSTANCES_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.DELETE_INSTANCE_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      successfulRequest: false,
    }),
    [actions.DELETE_INSTANCE_SUCCESS]: (state, action) => {
      const { id } = action.payload;
      const { instances, list } = state;
      delete instances[id];
      list.splice(list.indexOf(id), 1);
      return {
        ...state,
        instances: { ...instances },
        list: [...list],
        fetching: false,
        error: null,
      };
    },
    [actions.DELETE_INSTANCE_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.CREATE_INSTANCE_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      const { list, instances } = state;
      list.push(data._id);
      instances[data._id] = data;
      return {
        ...state,
        instances: { ...instances },
        list: [...list],
        fetching: false,
        error: action.payload.error,
      };
    },
    [actions.CREATE_INSTANCE_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.UPDATE_INSTANCE_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      const { instances } = state;
      const oldData = instances[data._id];
      instances[data._id] = { ...oldData, ...data };
      return {
        ...state,
        instances,
        fetching: false,
        error: null,
      };
    },
    [actions.UPDATE_INSTANCE_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.SET_INSTANCE]: (state, action) => {
      const { data } = action.payload;
      const { instances, instancesByInstanceId } = state;
      instances[data._id] = { ...data };
      instancesByInstanceId[data.instanceId] = { ...data };
      return {
        ...state,
        instances,
        instancesByInstanceId,
      };
    },
    [actions.CHECK_INSTANCES_STATUS]: (state, action) => {
      const { instances, list } = state;

      let liveData = {};

      if (action.payload.data) {
        const { data } = action.payload;
        data.forEach((d) => {
          liveData[d.instanceId] = d;
        });
      } else {
        liveData = state.liveData;
      }

      let compositorServersReady = false;
      let rtcServersReady = false;
      let socketServersReady = false;
      let decoderServerReady = false;

      const instancesData = list.map((id) => instances[id]);

      const socketAndCompositorInstances = instancesData.filter((instance) => instance.serverType !== 'RTC');

      const rtcServerInstances = instancesData.filter((instance) => instance.serverType === 'RTC');
      const rtcServerRunning = rtcServerInstances[0] && rtcServerInstances[0].status === 'running';
      if (rtcServerRunning) {
        rtcServersReady = liveData[rtcServerInstances[0].instanceId] && liveData[rtcServerInstances[0].instanceId].osUptime ? true : false;
      }

      const decoderServerInstances = instancesData.filter((instance) => instance.serverType === 'DECODER');
      const decoderServerRunning = decoderServerInstances[0] && decoderServerInstances[0].status === 'running';
      if (decoderServerRunning) {
        decoderServerReady = liveData[decoderServerInstances[0].instanceId] && liveData[decoderServerInstances[0].instanceId].osUptime ? true : false;
      }

      let runningInstances = 0;
      let socketServerRunning = false;
      let compositorServerRunning = false;
      for (const instance of socketAndCompositorInstances) {
        if (instance.status === 'running') {
          runningInstances += 1;
          if (instance.serverType === 'COMPOSITOR') {
            compositorServerRunning = true;
            compositorServersReady = liveData[instance.instanceId] && liveData[instance.instanceId].osUptime ? true : false;
          } else {
            socketServerRunning = true;
            socketServersReady = liveData[instance.instanceId] && liveData[instance.instanceId].osUptime ? true : false;
          }
        }
      }

      const socketServer = instancesData.filter((instance) => instance.serverType === 'SOCKET_SERVER');
      const beCount =
        liveData &&
        socketServer[0] &&
        liveData[socketServer[0].instanceId] &&
        liveData[socketServer[0].instanceId].adminBackendSocketCount &&
        liveData[socketServer[0].instanceId].adminBackendSocketCount > 0;
      const rtcCount =
        liveData &&
        socketServer[0] &&
        liveData[socketServer[0].instanceId] &&
        liveData[socketServer[0].instanceId].rtcSocketCount &&
        liveData[socketServer[0].instanceId].rtcSocketCount > 0;

      const allServersRunning = socketServerRunning && decoderServerRunning && compositorServerRunning && rtcServerRunning;

      const readyToStartEvent =
        socketAndCompositorInstances.length === runningInstances &&
        socketServersReady &&
        compositorServersReady &&
        decoderServerReady &&
        rtcServersReady &&
        beCount &&
        rtcCount;

      if (
        state.readyToStartEvent !== readyToStartEvent ||
        state.compositorServersReady !== compositorServersReady ||
        state.rtcServersReady !== rtcServersReady ||
        state.socketServersReady !== socketServersReady ||
        state.decoderServerReady !== decoderServerReady ||
        state.allServersRunning !== allServersRunning
      ) {
        return {
          ...state,
          readyToStartEvent,
          compositorServersReady,
          rtcServersReady,
          socketServersReady,
          decoderServerReady,
          allServersRunning,
          timestamp: Date.now(),
        };
      }
      return state;
    },
    [actions.SET_LIVE_INSTANCE_DATA]: (state, action) => {
      const { liveData } = state;
      const { data } = action.payload;
      data.forEach((d) => {
        const { instanceId } = d;
        liveData[instanceId] = { ...d };
      });
      return {
        ...state,
        liveData,
      };
    },
    [actions.CLEAR_LIVE_INSTANCE_DATA]: (state, action) => {
      const { liveData } = state;
      const { instanceId } = action.payload;

      liveData[instanceId] = {
        networkInOut: { total: { outputMb: 0, inputMb: 0 } },
        osUptime: 0,
        processUptime: 0,
      };

      return {
        ...state,
        liveData,
      };
    },
    [actions.END_LIVE_SUCCESS]: (state, action) => {
      return {
        ...state,
        isLive: false,
      };
    },
    [actions.GO_LIVE_SUCCESS]: (state, action) => {
      return {
        ...state,
        isLive: true,
      };
    },
    [actions.LAUNCH_FROM_IMAGE_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      runningFromImage: null,
    }),
    [actions.LAUNCH_FROM_IMAGE_SUCCESS]: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: null,
        runningFromImage: true,
      };
    },
    [actions.LAUNCH_FROM_IMAGE_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
      runningFromImage: false,
    }),
    [actions.GET_AWS_AVAILABILITY_ZONES_REQUEST]: (state) => ({
      ...state,
      availabilityZones: [],
      fetching: true,
      error: null,
    }),
    [actions.GET_AWS_AVAILABILITY_ZONES_SUCCESS]: (state, action) => ({
      ...state,
      availabilityZones: action.payload.data,
      fetching: false,
      error: null,
    }),
    [actions.GET_AWS_AVAILABILITY_ZONES_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
  },
  initialState,
);

export default reducer;
