import React, { PureComponent, createRef } from 'react';
import swal from '@sweetalert/with-react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Button from '../../common/Button';
import {
  getInstancesRequest,
  goLiveRequest,
  endLiveRequest,
  setLiveInstanceData,
  checkInstancesStatus,
  launchFromImageRequest,
  getAwsAvailabilityZonesRequest,
} from '../../redux/goLiveRedux';
import { updateRoutes, loadTopBar, sendUpdate } from '../../redux/uiRedux';
import { setLiveLayout, getEventParticipantsRequest, getEventParticipantsSuccess, updateEventRequest, setActiveEvent } from '../../redux/eventsRedux';
import { socketServerInstanceTypes, rtcServerInstanceTypes, decoderServerInstanceTypes } from '../../utils/aws-util';
import SocketClient from '../../utils/socket-client';
import StringUtils from '../../utils/string-utils';
import ImageCache from '../../utils/image-cache';
import Spinner from '../../common/Spinner';

import EndMeetingAlertMessage from './EndMeetingAlertMessage';
import Instance from './Instance';

export const enableGoLiveData = (activeStudio, getInstances, setLiveInstanceData, checkInstancesStatus) => {
  const timerId = setInterval(() => {
    getInstances(activeStudio._id, true);
  }, 1000);
  setTimeout(() => {
    // Socket server does not know the studioId and there is one socket server per studio
    SocketClient.joinRoom('server-resource-stats');
    SocketClient.on('stats', (data) => {
      setLiveInstanceData && setLiveInstanceData(data);
      checkInstancesStatus && checkInstancesStatus(data);
    });
  }, 3000);
  return timerId;
};

export const startEvent = async (activeStudio, activeEvent, goLive, updateEvent, instances, decoderServerRef, socketServerRef) => {
  try {
    if (activeEvent.logo) {
      const base64Logo = await ImageCache.get(activeEvent.logo);
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          if (img.naturalWidth > 1280 || img.naturalHeight > 720) {
            reject(new Error('The maximum allowed resolution for the Event logo is 1280x720'));
          } else {
            resolve();
          }
        };
        img.src = base64Logo;
      });
    }

    let wallMetadata = [
      [false, 'firstName', 'lastName'],
      [true, 'city', 'state'],
    ];
    if (activeEvent.hideAttendeeDetails) {
      wallMetadata = [[false, 'firstName'], [false]];
    }

    updateEvent(activeEvent._id, {
      wallMetadata,
      v1APIMessages: [{ type: 'reOpenMeeting', showName: activeEvent.name }],
    });

    const _start = async () => {
      goLive(activeStudio._id, activeEvent._id);
      SocketClient.emitAdminAppSync({
        type: 'GO_LIVE',
        payload: { eventId: activeEvent._id },
      });
      SocketClient.emit('wall-metadata', wallMetadata);
    };

    let sameAZ = true;
    const instancesAZs = Object.keys(instances).map((key) => {
      const i = instances[key];
      if (i.status === 'running') {
        return i.availabilityZone;
      }
    });

    instancesAZs.forEach((az) => {
      if (az && az !== activeStudio.awsDefaultAvailabilityZone) {
        sameAZ = false;
      }
    });

    if (!sameAZ) {
      const confirmed = await swal({
        title: 'Warning!',
        text: `It appears that not all servers are running within the default availability zone ${activeStudio.awsDefaultAvailabilityZone}. You can choose to continue but this could incur large costs. Would you still like to proceed?`,
        buttons: {
          cancel: 'No',
          confirm: 'Yes',
        },
        dangerMode: true,
      });
      if (confirmed) {
        if (
          (await checkSocketServerCapacity(activeEvent, instances, socketServerRef)) &&
          (await checkDecoderCapacity(activeEvent, instances, decoderServerRef)) &&
          (await checkRtcCapacity(activeEvent, instances, true))
        ) {
          _start();
        }
      }
    } else if (
      (await checkSocketServerCapacity(activeEvent, instances, socketServerRef)) &&
      (await checkDecoderCapacity(activeEvent, instances, decoderServerRef)) &&
      (await checkRtcCapacity(activeEvent, instances, true))
    ) {
      _start();
    }
  } catch (error) {
    swal({
      title: 'Error',
      text: error.message,
    });
  }
};

export const checkSocketServerCapacity = async (activeEvent, instances, socketServerRef) => {
  const socketServer = Object.values(instances).find((i) => i.serverType === 'SOCKET_SERVER');
  const socketServerValues = socketServerInstanceTypes.find((el) => el.value === socketServer.type);
  let checkPassed = true;
  if (activeEvent.maxOffWallAttendees > socketServerValues.maxOffWallParticipants) {
    const confirmed = await swal({
      title: 'Warning!',
      text: `The socket server instance type of ${socketServerRef.current.props.data.type} can not support ${activeEvent.maxOffWallAttendees} off wall attendees. Please change the instance type or update the event settings.`,
      buttons: {
        cancel: 'OK',
      },
      dangerMode: true,
    });
    if (!confirmed) {
      checkPassed = false;
    }
  }
  return checkPassed;
};

export const checkDecoderCapacity = async (activeEvent, instances, decoderServerRef) => {
  const decoderServer = Object.values(instances).find((i) => i.serverType === 'DECODER');
  const decoderValues = decoderServerInstanceTypes.find((el) => el.value === decoderServer.type);
  let checkPassed = true;
  if (activeEvent.maxOnWallAttendees > decoderValues.maxOnWallParticipants) {
    const confirmed = await swal({
      title: 'Warning!',
      text: `The decoder instance type of ${decoderServerRef.current.props.data.type} can not support ${activeEvent.maxOnWallAttendees} on wall attendees. Please change the instance type or update the event settings.`,
      buttons: {
        cancel: 'OK',
      },
      dangerMode: true,
    });
    if (!confirmed) {
      checkPassed = false;
    }
  }
  return checkPassed;
};

export const checkRtcCapacity = async (activeEvent, instances, startingEvent) => {
  const rtcServers = Object.values(instances).filter((i) => i.serverType === 'RTC');
  let rtcCapacity = 0;
  rtcServers.forEach((r) => {
    const rtcServerValues = rtcServerInstanceTypes.find((el) => el.value === r.type);
    if (startingEvent === true && rtcServerValues.status === 'running') {
      rtcCapacity += rtcServerValues.maxOnWallParticipants;
    } else {
      rtcCapacity += rtcServerValues.maxOnWallParticipants;
    }
  });
  if (activeEvent.maxOnWallAttendees > rtcCapacity) {
    await swal({
      title: 'Warning!',
      text: `The total RTC servers capacity can not support ${activeEvent.maxOnWallAttendees} on wall attendees. Please change the instance type or update the event settings.`,
      buttons: {
        cancel: 'OK',
      },
      dangerMode: true,
    });
    return false;
  }
  return true;
};

export const showEndEventMessage = async (activeStudio, endLive, setLiveLayout, activeEvent, updateEvent, participants, setActiveEvent) => {
  await swal({
    buttons: {},
    className: 'swal-custom-content',
    closeOnClickOutside: false,
    closeOnEsc: false,
    content: (
      <EndMeetingAlertMessage
        activeEvent={activeEvent}
        participants={participants}
        onCancel={() => swal.close()}
        onEndMeeting={({ redirectUrl, appendAttendeeInformation, shutdownGateway }) => {
          updateEvent(activeEvent._id, {
            redirectUrl,
            appendAttendeeInformation,
            v1APIMessages: [{ type: 'endMeeting', value: JSON.stringify({ redirectUrl, appendAttendeeInformation }) || '', showName: activeEvent.name }],
          });
          const event = { ...activeEvent, redirectUrl, appendAttendeeInformation };
          localStorage.setItem('activeEvent', JSON.stringify(event));
          setActiveEvent(event);
          endLive(activeStudio._id);
          setLiveLayout(null);

          if (shutdownGateway) {
            SocketClient.emit('gateway-command', { action: 'stop-process', processName: 'wall' });
            SocketClient.emit('gateway-command', { action: 'stop-process', processName: 'ndi' });
            SocketClient.emit('gateway-command', { action: 'stop-process', processName: 'audio' });
          }

          const SCREEN_WIDTH = 1280;
          const SCREEN_HEIGHT = 720;

          const wallWidthPx = SCREEN_WIDTH * activeStudio.columns;
          const wallHeightPx = SCREEN_HEIGHT * activeStudio.rows;

          const wallConfig = {
            id: Math.random().toString(),
            width: wallWidthPx,
            height: wallHeightPx,
            areas: [],
          };
          const hash = StringUtils.hash64(JSON.stringify(wallConfig));
          const data = { hash, ...wallConfig };
          SocketClient.emit('wall-config', data);
          SocketClient.emitAdminAppSync({
            type: 'END_LIVE',
            payload: { eventId: activeEvent._id },
          });

          swal.close();
        }}
      />
    ),
  });
};

class GoLiveContent extends PureComponent {
  constructor(props) {
    super(props);
    this._socketServerRef = createRef(null);
    this._decoderServerRef = createRef(null);
    this._compositorServerRef = createRef(null);
    this._rtcServerRefs = [];
    this._breakoutsServersRef = createRef(null);
    this._prevVisibilityState = 'visible';
    this.state = {
      isStartAllServersPressed: false,
      loading: props.appMode === 'PANDO',
    };
  }

  componentDidMount() {
    const {
      activeStudio,
      activeEvent,
      getEventParticipantsRequest,
      getEventParticipantsSuccess,
      getInstances,
      setLiveInstanceData,
      checkInstancesStatus,
      getAwsAvailabilityZones,
    } = this.props;

    if (activeStudio) {
      getAwsAvailabilityZones(activeStudio.awsRegion);
      this._liveDataTimerId = enableGoLiveData(activeStudio, getInstances, setLiveInstanceData, checkInstancesStatus);
      if (activeEvent) {
        this.setState({ loading: false });
        getEventParticipantsRequest(activeEvent._id);
        SocketClient.joinRoom(`${activeStudio._id}:${activeEvent._id}:participant-list`);
        SocketClient.on('participant-list-update', getEventParticipantsSuccess);
      }
    }
    document.addEventListener('visibilitychange', this._handleVisibilityChange, false);
  }

  componentDidUpdate(prevProps) {
    const { isLive, loadTopBar, allServersRunning } = this.props;
    if (prevProps.isLive !== isLive) {
      loadTopBar();
    }
    if (prevProps.allServersRunning !== allServersRunning && allServersRunning === true) {
      clearInterval(this._timerId);
    }
  }

  componentWillUnmount() {
    const { activeStudio, activeEvent } = this.props;
    if (activeStudio && activeEvent) {
      SocketClient.leaveRoom(`${activeStudio._id}:admin-app-sync`);
      SocketClient.leaveRoom(`${activeStudio._id}:${activeEvent._id}:participant-list`);
    }
    SocketClient.leaveRoom('server-resource-stats');
    SocketClient.removeAllListeners();
    clearInterval(this._timerId);
    clearInterval(this._liveDataTimerId);
    document.removeEventListener('visibilitychange', this._handleVisibilityChange, false);
  }

  _handleVisibilityChange = async () => {
    const { liveDataTimestamp } = this.props;
    if (this._prevVisibilityState === 'hidden' && document.visibilityState === 'visible' && SocketClient.socket.connected) {
      if (Date.now() - liveDataTimestamp > 60000) {
        const confirmed = await swal({
          title: 'Stale Connection',
          text: 'This tab has been inactive for too long. Please refresh the page.',
          buttons: {
            confirm: 'Refresh',
          },
        });
        if (confirmed) {
          window.location.reload();
        }
      }
    }
    this._prevVisibilityState = document.visibilityState;
  };

  _goLive = async () => {
    const { activeStudio, activeEvent, goLive, updateEvent, instances } = this.props;
    startEvent(activeStudio, activeEvent, goLive, updateEvent, instances, this._decoderServerRef, this._socketServerRef);
  };

  _endLive = async () => {
    const { activeStudio, endLive, setLiveLayout, activeEvent, updateEvent, participants, setActiveEvent } = this.props;
    showEndEventMessage(activeStudio, endLive, setLiveLayout, activeEvent, updateEvent, participants, setActiveEvent);
  };

  _startAllServers = async () => {
    const { activeEvent, instances } = this.props;
    if (
      (await checkSocketServerCapacity(activeEvent, instances, this._socketServerRef)) &&
      (await checkDecoderCapacity(activeEvent, instances, this._decoderServerRef)) &&
      (await checkRtcCapacity(activeEvent, instances))
    ) {
      clearInterval(this._timerId);
      this._timerId = setInterval(() => {
        const { compositorServersReady, rtcServersReady, socketServersReady, decoderServerReady } = this.props;
        if (!socketServersReady && this._socketServerRef.current.props.data.status === 'stopped') {
          this._socketServerRef.current._onStartHandler(true);
        } else if (socketServersReady && !decoderServerReady && this._decoderServerRef.current.props.data.status === 'stopped') {
          this._decoderServerRef.current._onStartHandler(true);
        } else if (socketServersReady && decoderServerReady && !rtcServersReady) {
          let totalRtcCapacity = 0;
          this._rtcServerRefs.forEach((r) => {
            if (totalRtcCapacity < activeEvent.maxOnWallAttendees && r.current.props.data.status === 'stopped') {
              r.current._onStartHandler(true);
            }
            const rtcServerType = r.current.props.data.type;
            const rtcServerValues = rtcServerInstanceTypes.find((el) => el.value === rtcServerType);
            totalRtcCapacity += rtcServerValues.maxOnWallParticipants;
          });
        }
        if (socketServersReady && decoderServerReady && !compositorServersReady && this._compositorServerRef.current.props.data.status === 'stopped') {
          this._compositorServerRef.current._onStartHandler(true);
        }
      }, 1000);
      this.setState({ isStartAllServersPressed: true });
    }
  };

  _stopAllServers = async () => {
    const confirmed = await swal({
      title: 'Warning!',
      text: 'Are you sure you want to stop all servers?',
      buttons: {
        cancel: 'No',
        confirm: 'Yes',
      },
      dangerMode: true,
    });
    if (confirmed) {
      this._socketServerRef.current._onStopHandler();
      this._decoderServerRef.current._onStopHandler();
      this._compositorServerRef.current._onStopHandler(true);
      this._rtcServerRefs.forEach((r) => {
        r.current._onStopHandler(true);
      });
    }
  };

  render() {
    const {
      goLiveEnabled,
      activeEvent,
      readyToStartEvent,
      socketServerList,
      compositorServerList,
      decoderServerList,
      rtcServerList,
      isLive,
      allServersRunning,
      instances,
      appMode,
    } = this.props;
    const { loading, isStartAllServersPressed } = this.state;
    if (!goLiveEnabled && appMode === 'PANDO') return <Redirect to='/app/studios' />;
    return (
      <div className='go-live'>
        <div className='content'>
          <div className='instances' style={{ display: loading ? 'flex' : 'none', position: 'absolute', height: '100%', vAlign: 'center', paddingTop: 300 }}>
            <Spinner />
          </div>
          <div className='instances' style={{ display: loading ? 'none' : 'flex' }}>
            {socketServerList.map((i) => (
              <Instance
                ref={this._socketServerRef}
                key={`socket-${i}`}
                instance={i}
                checkCapacity={() => checkSocketServerCapacity(activeEvent, instances, this._socketServerRef)}
              />
            ))}
            {decoderServerList.map((i) => (
              <Instance
                ref={this._decoderServerRef}
                key={`comp-${i}`}
                instance={i}
                checkCapacity={() => checkDecoderCapacity(activeEvent, instances, this._decoderRef)}
              />
            ))}
            {compositorServerList.map((i) => (
              <Instance ref={this._compositorServerRef} key={`comp-${i}`} instance={i} ignoreCheckCapacity />
            ))}
            {rtcServerList.map((i, index) => {
              this._rtcServerRefs[index] = createRef();
              return (
                <Instance
                  ref={(el) => {
                    this._rtcServerRefs[index].current = el;
                  }}
                  key={`rtc-${i}`}
                  instance={i}
                  checkCapacity={() => checkRtcCapacity(activeEvent, instances, this._rtcServerRefs)}
                />
              );
            })}
          </div>
          {appMode === 'PANDO' && (
            <div className='footer' style={{ display: loading ? 'none' : null }}>
              {activeEvent && !isLive && !allServersRunning && (
                <Button type={isStartAllServersPressed ? 'waiting' : 'primary'} onClick={this._startAllServers} text='Start All Servers' />
              )}
              {activeEvent && !isLive && readyToStartEvent && <Button type='danger' onClick={this._goLive} text='Start Event' />}
              {activeEvent && !isLive && readyToStartEvent && (!isStartAllServersPressed || allServersRunning) && (
                <Button type='danger' onClick={this._stopAllServers} text='Stop All Servers' />
              )}
              {activeEvent && isLive && <Button type='danger' onClick={this._endLive} text='End Event' />}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let socketServerList = [];
  let compositorServerList = [];
  let decoderServerList = [];
  let rtcServerList = [];

  if (state.goLive.list) {
    const instancesArray = state.goLive.list.map((instanceId) => state.goLive.instances[instanceId]);
    socketServerList = instancesArray.filter((instance) => instance.serverType === 'SOCKET_SERVER').map((i) => i._id);
    compositorServerList = instancesArray.filter((instance) => instance.serverType === 'COMPOSITOR').map((i) => i._id);
    decoderServerList = instancesArray.filter((instance) => instance.serverType === 'DECODER').map((i) => i._id);
    rtcServerList = instancesArray.filter((instance) => instance.serverType === 'RTC').map((i) => i._id);
  }
  return {
    liveRoutesEnabled: state.ui.liveRoutesEnabled,
    routesEnabled: state.ui.routesEnabled,
    goLiveEnabled: state.ui.goLiveEnabled,
    instances: state.goLive.instances,
    activeStudio: state.studio.active,
    activeEvent: state.events.active,
    readyToStartEvent: state.goLive.readyToStartEvent,
    isLive: state.goLive.isLive,
    socketServerList,
    compositorServerList,
    decoderServerList,
    rtcServerList,
    participants: state.events.participants,
    compositorServersReady: state.goLive.compositorServersReady,
    rtcServersReady: state.goLive.rtcServersReady,
    socketServersReady: state.goLive.socketServersReady,
    decoderServerReady: state.goLive.decoderServerReady,
    allServersRunning: state.goLive.allServersRunning,
    loading: state.goLive.loading,
    appMode: state.ui.appMode,
    liveDataTimestamp: state.goLive.timestamp,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInstances: (studio, monitoring) => dispatch(getInstancesRequest(studio, monitoring)),
  goLive: (studioId, eventId) => dispatch(goLiveRequest(studioId, eventId)),
  endLive: (studioId) => dispatch(endLiveRequest(studioId)),
  updateRoutes: () => dispatch(updateRoutes()),
  setLiveInstanceData: (data) => dispatch(setLiveInstanceData(data)),
  loadTopBar: () => dispatch(loadTopBar()),
  sendUpdate: (update) => dispatch(sendUpdate(update)),
  checkInstancesStatus: (data) => dispatch(checkInstancesStatus(data)),
  setLiveLayout: (layoutId) => dispatch(setLiveLayout(layoutId)),
  getEventParticipantsSuccess: (data) => dispatch(getEventParticipantsSuccess(data)),
  getEventParticipantsRequest: (event) => dispatch(getEventParticipantsRequest(event)),
  launchFromImage: (id) => dispatch(launchFromImageRequest(id)),
  updateEvent: (id, data) => dispatch(updateEventRequest(id, data)),
  setActiveEvent: (event) => dispatch(setActiveEvent(event)),
  getAwsAvailabilityZones: (regionId) => dispatch(getAwsAvailabilityZonesRequest(regionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoLiveContent);
