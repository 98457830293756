class ArrayUtil {
  static sort(arr, sortBy, sortDir) {
    const sorted = arr.sort((a, b) => {
      let p1Str = a[sortBy];
      let p2Str = b[sortBy];
      if (sortBy.toLowerCase() === 'name') {
        p1Str = `${b.participant.firstName} ${b.participant.lastName}`.trim();
        p2Str = `${a.participant.firstName} ${a.participant.lastName}`.trim();
      } else if (sortBy.toLowerCase() === 'duration') {
        p1Str = new Date(a.inScreeningQueueSince);
        p2Str = new Date(b.inScreeningQueueSince);
      }
      if (sortDir === 'desc') {
        if (p1Str < p2Str) {
          return -1;
        }
        if (p1Str > p2Str) {
          return 1;
        }
        return 0;
      } else {
        if (p1Str > p2Str) {
          return -1;
        }
        if (p1Str < p2Str) {
          return 1;
        }
        return 0;
      }
    });
    return sorted;
  }
}

export default ArrayUtil;
